import $ from "jquery";

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}


let cookieTheme = function () {
	let changeThemeBtn = $('.theme-btn');
	let cookieDark = "theme";

	if (getCookie(cookieDark) === "dark") {

		changeThemeBtn.addClass('active');
		$('body').addClass('theme-black');
	} else {

		changeThemeBtn.removeClass('active');
		$('body').removeClass('theme-black');
	}

}


// Set a Cookie
function setCookie(cName, cValue, expDays) {
	let date = new Date();
	date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

// Apply setCookie


let changeTheme = function () {

	let changeThemeBtn = $('.theme-btn');
	changeThemeBtn.on('click', toggleTheme);
	let nameCookie = 'theme';


	function toggleTheme() {
		const d = new Date();
		let time = d.getTime();
		let days = 7;

        let cookiesAccepted = getCookie('allowCookies_pref');

        console.log(cookiesAccepted)

		if (!changeThemeBtn.hasClass('active')) {

			if (cookiesAccepted === 'true') {
                let valueCookie = 'dark';
                setCookie(nameCookie, valueCookie, 30);
            }

			changeThemeBtn.addClass('active');
			$('body').addClass('theme-black');
		} else {

		    if (cookiesAccepted === 'true') {
                let valueCookie = 'normal';
                setCookie(nameCookie, valueCookie, 30);
            }

			changeThemeBtn.removeClass('active');
			$('body').removeClass('theme-black');
		}
	}

// TEST CHANGE PREFERENCE COLOR DETECT
// 	if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
// 		changeThemeBtn.addClass('active');
// 		$('body').addClass('theme-black');
//
// 	} else {
// 		changeThemeBtn.removeClass('active');
// 		$('body').removeClass('theme-black');
// 	}
}

// TEST CHANGE PREFERENCE COLOR DETECT
let preferenceTest = function () {
	let cookiesAccepted = getCookie('allowCookies_pref');
	let changeThemeBtn = $('.theme-btn');
	let cookieDark = "theme";

	if (window.matchMedia('(prefers-color-scheme: dark)').matches) {

		if (!cookiesAccepted) {
			changeThemeBtn.addClass('active');
			$('body').addClass('theme-black');

		} else {

			if (getCookie(cookieDark) === 'dark') {

				changeThemeBtn.addClass('active');
				$('body').addClass('theme-black');

			} else {

				changeThemeBtn.remove('active');
				$('body').remove('theme-black');
			}

		}

	} else {

		cookieTheme();
	}

}

preferenceTest();
// cookieTheme();
changeTheme();
