"use strict";

import simpleParallax from 'simple-parallax-js';

/* simplePARALLAX
* S E T T I N G S
orientation			String				up							up - right - down - left - up left - up right - down left - left right
scale				Number				1.2							need to be above 1.0
overflow			Boolean				false
delay				Number				0.4							the delay is in second
transition			String				'cubic-bezier(0,0,0,1)'		any CSS transition
customContainer		String or Node		""
customWrapper		String				""
maxTransition		Number				0							it should be a percentage between 1 and 99
*
* */


var images = document.getElementsByClassName('ux-parallax-observe');
new simpleParallax(images);

var images_down = document.getElementsByClassName('ux-parallax-observe-down');
new simpleParallax(images_down, {
	delay: .6,
	transition: 'cubic-bezier(0,0,0,1)'
});

var images_to_right = document.getElementsByClassName('ux-parallax-observe-to-right');
new simpleParallax(images_to_right, {
	overflow: true,
	scale: 1.5,
	orientation: "right",
	delay: .9,
	transition: 'cubic-bezier(0,0,0,1)'
});

