"use strict";

import $ from "jquery";
import "jquery.easing";
import "lazyload";

import { ParallaxNavigation } from "../js/scripts/ParallaxNavigation";


// Lazyload para imágenes
let images = document.querySelectorAll(".ux-lazyload");
lazyload(images);


// Navegación con Parallax en imágenes
let parallax = false;
$(function () {

    parallax = new ParallaxNavigation();
    parallax.init();
});

$(window).ready(function () {

    // Scroll animado entre Anchor
    $('.ux-scroll-observe').on('click', function (event) {
        let $anchor = $(this);

        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 0
        }, 1500, 'easeInOutExpo');

        event.preventDefault();
    });
});


// // Animación de carga de la página
// let loader = function () {
//
//     setTimeout(function () {
//
//         if ($('.site-loader').length > 0) {
//             $('.site-loader').removeClass('show');
//         }
//     }, 1);
// };
// loader();
const fade = () => {
    const wrapper = document.querySelector('.ux-wrapper-loader');
    wrapper.classList.add('fade');

};

// window.addEventListener('load', fade);

let mediumHeight = function () {

    $('.ux-mediumheight').css('min-height', ($(window).height() * 20) / 100);

    $(window).resize(function () {
        $('.ux-mediumheight').css('min-height', ($(window).height() * 20) / 100);
    });

    return false;
};
mediumHeight();


// TEST CHANGE PREFERENCE COLOR DETECT
// if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
// 	// dark mode
// 	$('body').addClass('theme-black');
// } else {
// 	$('body').removeClass('theme-black');
// }
