import $ from "jquery";

let navbarFullControl = function () {

    let navbarFull = $('.cmp-navbar-primary');
    let triggerBtn = $('.ux-hamburger-trigger');
    let triggerCloseNav = $('.ux-close-navbar');
    let btnHamburgerTrigger = $('.btn-hamburger-trigger');
    let isExpanded = navbarFull.attr('aria-expanded');

    function closeNav(){
        navbarFull.removeClass('ux-navbar-fullscreen-show');
        navbarFull.attr('aria-expanded', 'false');
        btnHamburgerTrigger.removeClass('open');
        enableScroll()
    }
    triggerCloseNav.on('click', closeNav);

    function toggleNav() {

        if (!navbarFull.hasClass('ux-navbar-fullscreen-show')) {

            navbarFull.addClass('ux-navbar-fullscreen-show');
            navbarFull.attr('aria-expanded', 'true');
            btnHamburgerTrigger.addClass('open');
            disableScroll();

        } else {

            navbarFull.removeClass('ux-navbar-fullscreen-show');
            navbarFull.attr('aria-expanded', 'false');
            btnHamburgerTrigger.removeClass('open');
            enableScroll();

        }
    }

    triggerBtn.on('click', toggleNav);

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    let keys = {37: 1, 38: 1, 39: 1, 40: 1, 32: 1, 33: 1, 34: 1, 35: 1, 36: 1};

    function preventDefault(e) {
        e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    let supportsPassive = false;
    try {
        window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
            get: function () {
                supportsPassive = true;
            }
        }));
    } catch (e) {
    }

    let wheelOpt = supportsPassive ? {passive: false} : false;
    let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // Disable middle click scroll
    function disableScrollWithMiddleClick(e) {

        if (e.button === 1 ) {

            e.preventDefault();
        }
    }

    function disableScroll() {

        window.addEventListener('mousedown', disableScrollWithMiddleClick);
        window.addEventListener('DOMMouseScroll', preventDefault, false);
        window.addEventListener(wheelEvent, preventDefault, wheelOpt);
        window.addEventListener('touchmove', preventDefault, wheelOpt);
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    function enableScroll() {

        window.removeEventListener('mousedown', disableScrollWithMiddleClick);
        window.removeEventListener('mousedown',preventDefault, false)
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
        window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
        window.removeEventListener('touchmove', preventDefault, wheelOpt);
        window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }





};

navbarFullControl();

/*
// Disable touchmove
document.addEventListener('touchmove', function(e) {
    e.preventDefault();

}, {passive:false});*/
