import $ from "jquery";

let lastScroll = 0;

let scrollWindow = function () {

    $(window).scroll(function () {



        let $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.cmp-navbar-primary')

        // let isExpanded = navbar.attr("aria-expanded");
        //
        // if (isExpanded){
        //     return;
        // }

        if (st > lastScroll) { // Scroll down

            if (st > 250) {
                if (!navbar.hasClass('scrolled')) {
                    navbar.addClass('scrolled');
                    //console.log('down, show scrolled, +150 far to top');
                }
            }

            if (st > 350) {
                navbar.removeClass('awake');
                navbar.addClass('sleep');
                //console.log('down, hide scrolled, +250 far to top');
            }

        } else { // Scroll up

            if (st < 250) {
                if (navbar.hasClass('scrolled')) {
                    navbar.removeClass('scrolled sleep');
                    //console.log('up, show normal, -150 next to top');
                }
            }

            if (st < 350) {
                if (navbar.hasClass('awake')) {
                    navbar.removeClass('awake');
                    navbar.addClass('sleep');
                    //console.log('up, hide scrolled, -250 near to top');
                }
            }

            if (st > 350) {
                if (!navbar.hasClass('awake')) {
                    navbar.addClass('awake');
                    //console.log('up, show scrolled, +250 far to top');
                }
            }
        }

        lastScroll = st;
    });
};
scrollWindow();
