window.addEventListener('DOMContentLoaded', () => {

	let aboutImg2 = document.querySelector('.about-img-2');
	let imgCnt = document.querySelector('.img-cnt');
	let playButton = document.querySelector('.play-button');
	let imageShapeVideo = document.querySelector('.image-shape-video');
	let videoShape = document.querySelector('.video-shape');

	document.addEventListener('click', function (e) {

		if (e.target.matches('.image-shape-video') || e.target.matches('.play-button')) {
			playButton.classList.add('disabled');
			imageShapeVideo.classList.add('disabled');
		}

	});

});
