document.addEventListener("DOMContentLoaded", function (event) {

	if (document.querySelector('.ux-search')) {

		// Obtenemos la estructura de la barra de búsqueda con el autocompletar

		const input = document.querySelector('.ux-search');
		const suggestions = document.querySelector('.ux-suggestions');
		const suggestionsList = document.querySelector('.ux-suggestionsList');
		const uiShowAutocomplete = "ui-show-autocomplete";


		input.addEventListener("keyup", function () {

			const query = input.value;

			// Usamos fetch en vez de ajax para evitar cargar la librería de jquery

			// Le pasamos a fetch la ruta y le decimos que espera una consulta (query)
			// La cual obtenemos en el controlador.

			fetch("/autocomplete?query=" + query, {method: "GET"})
				.then(response => response.json()) // Pedimos un json con los datos
				.then(data => { // Almacenamos los datos en "data"


					// Guardamos en una constante un array con los items pedidos mediante fetch
					const items = data;

					suggestionsList.innerHTML = '';

					// Recorremos el array para ver los items en el menú dropdown de la barra de búsqueda
					items.forEach(function (data) {

						const li = document.createElement('li');
						const a = document.createElement('a');

						// Creamos los elementos que queremos que aparezcan en el dropdown

						// Definimos el atributo href con la ruta correspondiente
						li.classList.add('dropdown-item');
						a.classList.add('dropdown-link');
						a.href = window.location.origin + "/blog/" + data.slug;
						a.innerHTML = data.title;

						suggestionsList.appendChild(li);
						li.appendChild(a)

					});

					if (input.value) {

						// Si hay elementos, añadimos la clase show
						suggestions.classList.add(uiShowAutocomplete);

						document.addEventListener('click', function (event) {

							if (!input.contains(event.target) && !suggestions.contains(event.target)) {

								suggestions.classList.remove(uiShowAutocomplete)
							}

						})

						if (!data.length) {

							// Si no hay elementos en el array, eliminamos la clase show
							suggestions.classList.remove(uiShowAutocomplete);

						}

					} else {

						suggestions.classList.remove(uiShowAutocomplete);
					}

				})
				.catch(error => console.error(error));


		})

		document.querySelector('.i-close').addEventListener('click', function () {

			input.value = '';

		})
	}


});
