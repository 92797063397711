window.addEventListener('DOMContentLoaded', () => {

	// VARIABLES
	let ccc						= document.querySelector('[data-param="ccc"]');
	let cccBarModal 			= document.querySelector('[data-param="ccc-modal"]');
	let cccBarBtnSetUp 			= document.querySelector('[data-param="ccc-bar-btn-setup"]');
	let cccBarBtnAccept 		= document.querySelector('[data-param="ccc-bar-btn-accept"]');
	let cccBarBtnDeny 			= document.querySelector('[data-param="ccc-bar-btn-deny"]');
	let cccBarBtnDenyAll		= document.querySelector('[data-param="ccc-bar-btn-denyAll"]');
	let cccBarBtnSaveSetup 		= document.querySelector('[data-param="ccc-bar-btn-saveSetup"]');
	let cccBarBtnAcceptAll 		= document.querySelector('[data-param="ccc-bar-btn-acceptAll"]');
	let cccBarBtnRibbonInner	= document.querySelector('[data-param="ccc-bar-btn-ribbon-inner"]');
	let cookiesPrefCheckbox		= document.querySelector('[data-param="ccc-pref-checkbox"]');
	let cookiesStatsCheckbox	= document.querySelector('[data-param="ccc-stats-checkbox"]');
	let cookiesMarkCheckbox		= document.querySelector('[data-param="ccc-mark-checkbox"]');

	// Get Body element
	let documentBody			= document.querySelector('body');

	// COLLAPSABLE LIST
	let collapsableListArray = document.querySelectorAll('[data-param="collapsable-list"]');

	/**
	 * Iterate the array
	 */
	for ( let list of collapsableListArray) {

		// click event delegation of each list
		list.addEventListener('click', (e) => {

			let target 				= e.target;
			let targetParentElement = target.parentElement;

			if (target.matches('.cl-item-header')) {

				let targetBody 			= targetParentElement.querySelector('.cl-description-body');
				let targetBodyContent 	= targetBody.querySelector('.cl-description-body-content');
				let targetAngleDown     = target.querySelector('.angle-down');

				if (targetParentElement.classList.contains('collapsed')) {

					// the height of the body will fit to its content
					targetBody.style.height = targetBodyContent.offsetHeight + 'px';
					targetAngleDown.classList.add('expanded');

					targetParentElement.classList.remove('collapsed');

					// changing the height to auto to allow resizing while the item is not collapsed
					// the timeout must be the transition time !!!
					setTimeout( () => {
						targetBody.style.height = 'auto';
					}, 250 )

				} else {

					targetParentElement.classList.add('collapsed');
					targetAngleDown.classList.remove('expanded');

					// changing the height from auto to px to allow the transition
					targetBody.style.height = targetBodyContent.offsetHeight + 'px';

					// we set a 0 timeout to separate the two processes of resizing height
					setTimeout( () => {
						targetBody.style.height = '0';
					})
				}
			}
		});
	}



	// UTILITIES
	// Get seconds of transition of element in seconds (ms = true to return it in milliseconds)
	function getCSSTransitionDuration ( element, ms = false ) {

		return (parseFloat(window.getComputedStyle(element).transitionDuration)) * (ms ? 1000 : 1)

	}



	// FUNCTIONS TO MANIPULATE COOKIES (document.cookie)
	// returns string w the value of the cookie
	function getCookie(cookieName){

		let name = cookieName + "=";
		let cookiesArray = document.cookie.split(';');

		for(let i = 0; i < cookiesArray.length; i++) {

			let cookie = cookiesArray[i];

			// to delete the initial space " "
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}

			// to get only the value
			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}

		//if cookie not found
		return "";

	}

	// set a cookie, if day is not defined it will last forever
	function setCookie(name, value, days) {

		// if days is not defined, the cookie will last for 2 years
		if (!days) {
			days = 730;
		}

		// creating expiration date
		let date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		let expires = "; expires=" + date.toUTCString();

		// setting cookie
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	// permanently erase a cookie setting its expiration date to yesterday
	function eraseCookie(cookieName) {
		setCookie(cookieName,"",-1);
	}

	// permanently erase all cookies setting its expiration date to yesterday
	function eraseAllCookies() {

		const cookiesArray = document.cookie.split(";");

		for (let i = 0; i < cookiesArray.length; i++) {

			const cookie = cookiesArray[i];

			const eqPos = cookie.indexOf("=");
			const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

			eraseCookie(cookieName);
		}
	}

	// permanently erase all cookies starting with "value"
	function eraseAllCookiesStartingWith(value) {

		const cookiesArray = document.cookie.split(";");

		for (let i = 0; i < cookiesArray.length; i++) {

			const cookie = cookiesArray[i];

			// Check if the cookie name starts with value
			if (cookie.substring(0, value.length) === value) {

				const eqPos = cookie.indexOf("=");
				const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

				eraseCookie(cookieName);
			}
		}
	}



	// FUNCTIONS TO CONTROL COOKIES
	function acceptAllCookies() {

		setCookie('allowCookies', 'true');
		setCookie('allowCookies_pref', 'true');
		setCookie('allowCookies_stats', 'true');
		setCookie('allowCookies_mark', 'true');
		cookiesPrefCheckbox.checked 	= true;
		cookiesStatsCheckbox.checked 	= true;
		cookiesMarkCheckbox.checked 	= true;

		// TEST CHANGE PREFERENCE COLOR DETECT
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			setCookie('theme', 'dark', 30);
		}

		loadPreferences();
		loadStatistics();
		loadMarketing();
	}

	// to accept only cookies whose input is checked
	function acceptCheckedCookies() {

		setCookie('allowCookies', 'true');

		if (cookiesPrefCheckbox.checked) {
			setCookie('allowCookies_pref', 'true');

			// TEST CHANGE PREFERENCE COLOR DETECT
			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				setCookie('theme', 'dark', 30);
			}

			loadPreferences();
		} else {
			setCookie('allowCookies_pref', 'false');
			unloadPreferences();
		}

		if (cookiesStatsCheckbox.checked) {
			setCookie('allowCookies_stats', 'true');
			loadStatistics();
		} else {
			setCookie('allowCookies_stats', 'false');
			unloadStatistics();
		}

		if (cookiesMarkCheckbox.checked) {
			setCookie('allowCookies_mark', 'true');
			loadMarketing();
		} else {
			setCookie('allowCookies_mark', 'false');
			unloadMarketing()
		}

	}

	function denyAllCookies() {

        eraseAllCookies();
		setCookie('allowCookies', 'false');

		cookiesPrefCheckbox.checked 	= false;
		cookiesStatsCheckbox.checked 	= false;
		cookiesMarkCheckbox.checked 	= false;

		unloadPreferences();
		unloadStatistics();
		unloadMarketing();
	}


	/* GOOGLE CONSENT TAG MANAGER */
	// https://support.google.com/tagmanager/answer/10718549?hl=en


	// Cookies Marketing
	// Enables storage (such as cookies) related to advertising
	function consentAdStorage() {

	}

	// Cookies Analytics
	// Enables storage (such as cookies) related to analytics e.g. visit duration
	function consentAnalyticsStorage() {
		gtag('consent', 'update', {
			'analytics_storage': 'granted',
		});
	}

	/* GOOGLE CONSENT TAG MANAGER END */


	// LOADING ACCEPTED COOKIES
	function loadPreferences(){

		// Read cookies that are written and load their values
		// EXAMPLE: THEME SELECTION
		// let themeValue 		= getCookie('pref_theme');
		// let themeComponent 	= document.querySelector('theme_component');
		// themeComponent.classList.add(themeValue);
		//
		// document.addEventListener('click', (e) => {
		//
		// 	if (e.target === document.querySelector('[data-param="theme-default-button"]')) {
		// 		setCookie('pref_theme', 'theme-default');
		// 	}
		// 	if (e.target === document.querySelector('[data-param="theme-black-button"]')) {
		// 		setCookie('pref_theme', 'theme-black');
		// 	}
		// });
	}

	function unloadPreferences() {

		// All preferences preferences cookies has to start with "pref_"!!!!!!!!!!!
		eraseAllCookiesStartingWith('pref_');
	}

	function loadStatistics() {

		// Declare every statistics component in an Array
		let cookieStatisticsComponents = document.querySelectorAll('[data-param="cookie_statistics"]');

		// LOAD CLASS HAS TO CHANGE DISPLAY!!!!!
		for ( let component of cookieStatisticsComponents) {
			component.classList.add('load');
		}

		// TagManager Statistics consent to granted
		consentAnalyticsStorage();
	}

	function unloadStatistics() {

		// Declare every statistics component in an Array
		let cookieStatisticsComponents = document.querySelectorAll('[data-param="cookie_statistics"]');

		// LOAD CLASS HAS TO CHANGE DISPLAY!!!!!
		for (let component of cookieStatisticsComponents) {
			component.classList.remove('load');
		}
	}

	function loadMarketing() {

		// Declare every marketing component in an Array
		let cookieMarketingComponents = document.querySelectorAll('[data-param="cookie_marketing"]');

		// LOAD CLASS HAS TO CHANGE DISPLAY!!!!!
		for ( let component of cookieMarketingComponents) {
			component.classList.add('load');
		}

		// TagManager Marketing Consent update to granted
		consentAdStorage();
	}

	function unloadMarketing() {

		// Declare every marketing component in an Array
		let cookieMarketingComponents = document.querySelectorAll('[data-param="cookie_marketing"]');

		// LOAD CLASS HAS TO CHANGE DISPLAY!!!!!
		for ( let component of cookieMarketingComponents) {
			component.classList.remove('load');
		}
	}



	// COOKIE MODAL DISPLAY
	function openCookieModal() {

		cccBarModal.classList.remove('ccc-d-none');

		// Once the display is not none, we add 'active' class to have the opacity transition
		setTimeout( ()=> {
			cccBarModal.classList.add('active');
			documentBody.classList.add('overflow-y-none');
		}, 0.001 );

	}

	function collapseCookieModal() {

		// Remove 'active' class to have opacity transition
		cccBarModal.classList.remove('active');
		documentBody.classList.remove('overflow-y-none');

		// Once the opacity transition has ended, the modal display changes to none
		setTimeout( ()=> {
			cccBarModal.classList.add('ccc-d-none')
		}, getCSSTransitionDuration(cccBarModal, true));

	}



	// COOKIE BAR DISPLAY
	function openCookieBar() {

		// Collapse the cookies bar
		ccc.classList.remove('ccc-collapsed');

	}

	function collapseCookieBar() {

		// Collapse the cookies modal
		collapseCookieModal();

		// Collapse the cookies bar
		ccc.classList.add('ccc-collapsed');

	}


	// CLICK EVENT DELEGATION
	ccc.addEventListener('click', (e) => {

		// RIBBON
		/* Ribbon cookie Button:
			- Open Bar
		*/
		if (e.target === cccBarBtnRibbonInner) {

			openCookieBar();

		}


		// BAR
		/* Setup Button:
		 	- Open Modal
		 */
		if (e.target === cccBarBtnSetUp) {

			openCookieModal();

		}

		/* Deny Button
			- Deny all Cookies
			- Close Bar
		*/
		if (e.target === cccBarBtnDeny) {

			denyAllCookies();
			collapseCookieBar();
		}

		/* Accept Button
			- Accept all Cookies
			- Close Bar
		*/
		if (e.target === cccBarBtnAccept) {

			acceptAllCookies();
			collapseCookieBar();
		}


		// MODAL
		/* Deny All Cookies Button:
			- Deny all cookies
		 	- Close bar
		 */
		if (e.target === cccBarBtnDenyAll) {

			denyAllCookies();
			collapseCookieBar();
		}

		/* Save Setup Cookies Button:
			- Accept only cookies whose input is checked
			- Close bar
		 */
		if (e.target === cccBarBtnSaveSetup) {

			acceptCheckedCookies();
			collapseCookieBar();
		}

		/* Accept All Cookies Button:
			- Accept all Cookies
			- Close bar
		 */
		if (e.target === cccBarBtnAcceptAll) {

			acceptAllCookies();
			collapseCookieBar();
		}

	})



	// INITIATE COOKIEBAR
	function initCookieBar() {
		// First visit
		if (getCookie( 'allowCookies') === '' ) {

			// Barra sin colapsar
			openCookieBar();
			// No se cargan cookies

		}

		// Cookies accepted
		if (getCookie( 'allowCookies') === 'true' ) {

			// Collapse bar
			collapseCookieBar();

			// Read accepted cookies, then load resources and turn is input to checked

			// Preferences cookies
			if (getCookie( 'allowCookies_pref') === 'true' ) {

				cookiesPrefCheckbox.checked = true;
				loadPreferences();
			}

			// Statistics cookies
			if (getCookie( 'allowCookies_stats') === 'true' ) {

				cookiesStatsCheckbox.checked = true;
				loadStatistics();
			}

			// Marketing cookies
			if (getCookie( 'allowCookies_mark') === 'true' ) {

				cookiesMarkCheckbox.checked = true;
				loadMarketing();
			}
		}

		// Declined cookies
		if (getCookie( 'allowCookies') === 'false' ) {

			// Collapsed bar
			collapseCookieBar();
		}
	}

	initCookieBar();

}, false);
